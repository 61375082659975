/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location }) => {
  anchorScroll(location)
  return true
}
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  anchorScroll(location)
  return true
}

// Watch for hash and scroll browser
function anchorScroll(location) {
  if (location && location.hash) {
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`).offsetTop
      const iNavHeight = document.querySelector(
        ".axp-global-header__dls__module___2gPze"
      ).offsetHeight
      window.scrollTo({ top: item - iNavHeight, left: 0, behavior: "smooth" })
    }, 0)
  }
}
